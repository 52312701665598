html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: suisse_intlregular;
  src: url("suisseintl-regular-webfont.8425daf5.woff2") format("woff2"), url("suisseintl-regular-webfont.e3de11ee.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

:root {
  --color-primary: #3dbd9f;
  --color-primary-darker: #2e8e73;
  --color-secondary: #00d9ff;
  --color-accent: #ff3400;
  --color-headings: #3b3b3b;
  --color-body: #918ca4;
  --color-body-darker: #5c5577;
  --color-border: #e6ebee;
  --color-nav-element: #d2d0db;
  --color-card: #84bbbd;
  --border-radius: 100px;
  --gradient-primary: linear-gradient(to top left, #39b385, #9be15d);
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  color: #444;
  background-color: #f3f3f3;
  font-family: Poppins, sans-serif;
  font-weight: 300;
  line-height: 1.9;
  position: relative;
  overflow-x: hidden;
}

.header {
  position: relative;
}

.nav {
  width: 100%;
  height: auto;
  z-index: 10;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 4rem;
  transition: all .5s;
  display: flex;
  position: absolute;
  top: 0;
}

.nav__home {
  width: 39px;
  height: 45px;
  grid-area: 1 / 1 / 2 / 2;
  display: inline;
}

.nav__toggler {
  cursor: pointer;
  color: var(--color-primary);
  grid-area: 1 / 1 / 2 / 2;
  justify-self: end;
  margin: 2rem 2rem 0 0;
  display: none;
}

.nav__toggler--close {
  cursor: pointer;
  grid-area: 1 / 1 / 2 / 2;
  justify-self: end;
  margin: 2rem 2rem 0 0;
  display: none;
}

@media screen and (max-width: 980px) {
  body {
    overflow-x: hidden;
  }

  .nav {
    width: 100%;
    height: auto;
    z-index: 10;
    z-index: 200;
    background: #fff;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
    padding: 0;
    display: grid;
    position: absolute;
    top: 0;
  }

  .nav__home {
    margin: 2rem 0 0 2rem;
  }

  .nav__logo {
    grid-area: 1 / 1 / 2 / 2;
  }

  .nav > .nav__links {
    background-color: #fff;
    border-radius: 0;
    flex-direction: column;
    padding: 0;
    transition: all .5s;
    display: flex;
  }

  .nav__links > .nav__item {
    width: 100%;
    border-bottom: 1px solid #000;
    padding: 1rem;
  }

  .nav .nav__item span {
    color: #757575;
    font-size: 18px;
    font-weight: 600;
    transition: all .3s;
  }

  .nav .nav__item:hover span {
    color: #000;
  }

  .nav .nav__item--highlight {
    border: none;
    margin-left: 1.5rem;
  }

  .nav .nav__item--highlight .btn__highlight {
    background-color: var(--color-primary);
    color: #fff;
    padding: .5rem 1rem;
  }

  .nav .nav__item--highlight:hover .btn__highlight {
    background-color: var(--color-primary-darker);
    color: #fff;
  }

  .nav > .nav__toggler, .nav > .nav__toggler--close {
    display: block;
  }

  .nav.sticky {
    background-color: #fff;
    position: fixed;
  }

  .nav__links .nav__link:before {
    display: none;
  }
}

.nav__back {
  width: 100%;
  height: 12rem;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.sticky {
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background-color: #152b3db3;
  position: fixed;
}

.nav__logo {
  height: 45px;
}

.nav__logo:hover {
  cursor: pointer;
}

.nav__links {
  border-radius: var(--border-radius);
  background-color: #fff3;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  column-gap: .5rem;
  padding: .2rem .2rem .2rem 4.2rem;
  list-style: none;
  display: flex;
}

.nav__link {
  padding: 0 1.5rem;
  text-decoration: none;
  position: relative;
}

.nav__link:before {
  content: "";
  transform-origin: center;
  width: 100%;
  height: 34px;
  z-index: -1;
  opacity: 0;
  background: #152b3d4d;
  border-radius: 20px;
  transition: all .5s cubic-bezier(.165, .84, .44, 1);
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nav__link:hover:before {
  opacity: 1;
}

.nav__btn {
  text-decoration: none;
}

.nav__item--highlight {
  padding: 1.4rem .2rem 1.4rem 1rem;
}

.nav__item span {
  color: #fff;
  letter-spacing: .5px;
  font-size: 14px;
  font-weight: 400;
}

.nav__btn span {
  color: #fff;
  letter-spacing: .5px;
  font-size: 14px;
  font-weight: 600;
}

.btn__highlight {
  border-radius: var(--border-radius);
  background-color: var(--color-primary);
  padding: 1.5rem 3.4rem;
  transition: all .3s;
}

.btn__highlight:hover {
  color: var(--color-primary);
  background-color: #fff;
}

.collapsible {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.collapsible__expanded {
  max-height: 100vh;
  opacity: 1;
}

.header__title {
  position: relative;
}

.header__heading {
  z-index: 5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header__heading--h1 {
  color: #fff;
  width: 100%;
  white-space: nowrap;
  margin-bottom: 0;
  font-size: 8rem;
  font-weight: 100;
}

.header__heading--h4 {
  color: #fff;
  width: auto;
  font-size: 2rem;
  font-weight: 100;
}

.header__img {
  width: 100%;
  height: auto;
  animation-name: scaleDown;
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-direction: normal;
  display: inline-block;
}

@keyframes scaleDown {
  0% {
    transform: scale(1.45);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 980px) {
  .header__title {
    z-index: 100;
  }

  .header__heading--h1 {
    white-space: nowrap;
    font-size: 4.8rem;
  }

  .header__img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
}

.overlay:before {
  content: "";
  width: 100%;
  height: 99.2%;
  z-index: 2;
  background-color: #47474780;
  position: absolute;
  top: 0;
  left: 0;
}

.section__header {
  text-transform: capitalize;
}

.section {
  scroll-margin-top: 80px;
  transition: all 1s;
}

.section--hidden {
  opacity: 0;
  transform: translateY(8rem);
}

#intro {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

#section--1 {
  text-align: center;
  grid-template-rows: .3fr 1fr;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  transition: all .3s;
  display: grid;
  position: relative;
}

#section--1 .section__header {
  color: var(--color-headings);
  letter-spacing: -3px;
  grid-area: 1 / 1 / 2 / 3;
  font-family: Zen Antique, serif;
  font-size: 4rem;
  font-weight: 500;
}

.underline {
  width: 8rem;
  border-bottom: 5px solid #3b3b3b;
  grid-area: 1 / 1 / 2 / 3;
  padding-bottom: 10px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 150%);
}

.section__img {
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
  justify-self: end;
  margin: 6rem 4rem 0 5rem;
  box-shadow: 0 0 40px 10px gray;
  -webkit-mask-image: url("data:image/svg+xml;utf8,<svg preserveAspectRatio=\"none\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0\" y=\"0\" viewBox=\"0 0 100 100\" xml:space=\"preserve\"><style type=\"text/css\">.blur{filter:url(%23softedge);}</style><filter id=\"softedge\"><feGaussianBlur stdDeviation=\"2.5\"></feGaussianBlur></filter><g class=\"blur\"><rect x=\"20\" y=\"3\" width=\"100\" height=\"80\"/></g></svg>");
  -webkit-mask-size: cover;
}

.section__description {
  text-align: justify;
  grid-area: 2 / 2 / 3 / 3;
  justify-self: start;
  margin-left: 4rem;
  padding: 5rem 12rem 5rem 0;
  font-family: suisse_intlregular;
  font-size: 2rem;
  font-style: italic;
}

.divider {
  width: 46rem;
  height: 27.6rem;
  display: block;
}

.divider--left {
  margin-left: 5rem;
}

.divider--right {
  align-self: end;
}

@media screen and (max-width: 980px) {
  .divider--left {
    width: 60%;
    transform: translate(-50px, -50px);
  }

  .divider--right {
    width: 60%;
    transform: translate(0, -30%);
  }

  .divider--3 {
    transform: translate(-50px, -120px);
  }

  #section--1 {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
    padding: 0 1rem;
    display: grid;
    transform: translateY(-60px);
  }

  #section--1 .section__header, .underline {
    grid-area: 1 / 1 / 2 / 2;
  }

  .section__description {
    text-align: center;
    grid-area: 3 / 1 / 4 / 2;
    margin: 0;
    padding: 5rem;
  }

  .section__img {
    -webkit-mask-size: none;
    max-width: 40rem;
    grid-area: 2 / 1 / 3 / 2;
    place-self: start center;
    margin: 4rem 1rem 0;
    -webkit-mask-image: none;
  }
}

#section--2 {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#f3f3f3", endColorstr= "#f3f3f3", GradientType= 1);
  background: linear-gradient(#f3f3f3 0%, #86c4b96a 51%, #f3f3f3 100%);
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 2.5rem 5rem;
  transition: all .3s;
  display: grid;
}

#section--2 .section__header {
  grid-area: 1 / 1 / 2 / 4;
  font-size: 2.3rem;
  display: inline-block;
}

.icon__container {
  width: 8em;
  height: 8em;
  background-color: #f3f3f3;
  border-radius: 50%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  margin-left: 1rem;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.card {
  background-color: var(--color-card);
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  transition: all .3s;
  display: flex;
  overflow: hidden;
}

.card:hover {
  transform: scale(1.03);
}

.card__header {
  width: 100%;
  position: relative;
}

.card__image {
  width: 100%;
  height: 100%;
  animation-name: scaleDown;
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-direction: normal;
  display: inline-block;
}

.card__heading {
  text-transform: capitalize;
  padding: 3rem 3.2rem 1rem;
  font-family: suisse_intlregular;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 2.5rem;
}

.card__description {
  color: #fff;
  padding: 1rem 3.2rem 2rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.6em;
}

.crop {
  width: 100%;
  height: 35vh;
}

.link-arrow {
  text-transform: uppercase;
  color: #c3934b;
  max-width: 13rem;
  margin-top: 4rem;
  font-family: suisse_intlregular;
  font-size: 1.6rem;
  font-weight: 600;
  text-decoration: none;
}

.link-arrow:after {
  content: "";
  margin-left: 4px;
  font-family: FontAwesome;
  transition: transform .15s;
  display: inline-block;
}

.link-arrow:hover:after {
  transform: translateX(8px);
}

.card__middle {
  grid-area: 3 / 2 / 4 / 3;
}

@media screen and (max-width: 980px) {
  #section--2 {
    flex-direction: column;
    padding: 2.5rem 2rem;
    display: flex;
    transform: translateY(-100px);
  }

  #section--2 .card {
    align-self: center;
  }

  .card {
    width: 95%;
    height: -moz-fit-content;
    height: fit-content;
  }

  .card .card__description {
    line-height: 3rem;
  }

  .crop {
    width: 100%;
    height: 25vh;
  }
}

#section--3 {
  margin-top: 5rem;
  transition: all .3s;
  position: relative;
}

#section--3:before {
  content: "";
  width: 100%;
  height: 110%;
  z-index: -5;
  background: #fff;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

#section--3 .section__header {
  text-align: center;
  font-size: 44px;
  font-weight: 100;
}

.section__subheader {
  margin-bottom: 4.5rem;
}

#section--3 .section__subheader {
  text-align: center;
  font-size: 20px;
  font-weight: 100;
}

.span__highlight {
  white-space: nowrap;
  position: relative;
}

.span__highlight:after {
  content: "";
  width: 100%;
  height: 100%;
  background-image: var(--gradient-primary);
  z-index: -1;
  opacity: .7;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.07, 1.05)skewX(-15deg);
}

.form {
  grid-template-rows: 80px 80px 200px 110px;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem 2rem;
  padding: 0 8rem;
  display: grid;
}

.form__container {
  transform: translateY(30px);
}

.input-field {
  background: #eff3fd;
  border: none;
  border-radius: 10px;
  outline: 0;
  padding: 0 2rem;
  font-size: 18px;
  transition: all .3s;
}

.input-field:focus {
  box-shadow: 0 0 2px 3px #a8e6cf;
}

.textarea {
  overflow-wrap: break-word;
  resize: none;
  background: #eff3fd;
  border: none;
  border-radius: 10px;
  outline: 0;
  grid-area: 3 / 1 / 4 / 3;
  padding: 2rem 2rem 0;
  font-size: 18px;
  transition: all .3s;
  overflow-x: hidden;
}

.textarea:focus {
  box-shadow: 0 0 2px 3px #a8e6cf;
}

.btn__submit {
  cursor: pointer;
  border: none;
  outline: 0;
  grid-area: 4 / 1 / 5 / 3;
  margin-top: 3rem;
}

.form .btn__submit:hover {
  background-color: var(--color-primary-darker);
}

.btn__label {
  color: #fff;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  transition: all .3s;
  display: flex;
}

.icon-arrow {
  transition: all .3s;
}

@media screen and (max-width: 980px) {
  #section--3 {
    transform: translateY(-150px);
  }

  .form {
    flex-direction: column;
    padding: 0 2rem;
    display: flex;
  }

  .input-field {
    width: 100%;
    height: 8rem;
  }

  .textarea {
    height: 20rem;
    padding: 3.2rem 2rem;
  }

  .btn__submit {
    height: 8rem;
  }
}

.footer {
  position: relative;
  top: 2rem;
}

.footer__container {
  grid-template-rows: repeat(3, auto);
  grid-template-columns: repeat(2, 1fr);
  row-gap: 2rem;
  padding: 12rem 20rem 5rem;
  display: grid;
}

.footer:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -10;
  background-color: #245c3f;
  position: absolute;
  top: 0;
  left: 0;
}

.footer__logo {
  height: 50px;
  grid-area: 2 / 1 / 3 / 3;
  justify-self: center;
  margin: 3rem 0;
}

.footer__nav {
  flex-direction: row;
  grid-area: 1 / 1 / 2 / 3;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
  display: flex;
}

.footer__link {
  color: #d1d1d1;
  font-size: 1.6rem;
  text-decoration: none;
  transition: all .3s;
}

.footer__link:hover {
  color: #fff;
}

.footer__copyright {
  color: #bfbfbf;
  grid-area: 3 / 1 / 4 / 2;
  place-self: center end;
  margin-right: 6rem;
  font-size: 1.4rem;
}

.email {
  flex-direction: column;
  align-self: self-start;
  display: flex;
}

.vertical-line {
  height: 100%;
  width: 1px;
  background-color: #fff;
  grid-area: 3 / 1 / 4 / 3;
  justify-self: center;
}

.contact__container {
  width: 100%;
  flex-direction: column;
  grid-area: 3 / 2 / 4 / 3;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-left: 6rem;
  display: flex;
}

.col__header {
  color: #ffffff80;
  font-size: 1.5rem;
}

.footer__nav > .col__header {
  display: none;
}

.address {
  color: #d1d1d1;
  white-space: nowrap;
  font-size: 1.5rem;
  font-style: normal;
}

.company__address {
  width: 100%;
  height: 100%;
  color: #d1d1d1;
  align-self: center;
  font-size: 1.5rem;
  font-style: normal;
}

.contact {
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  display: grid;
}

.footer__icon {
  width: 32px;
  height: 32px;
  display: inline-block;
}

.icon-container {
  cursor: pointer;
  grid-area: 2 / 1 / 3 / 3;
  transform: translateY(5px);
}

#icon-linkedin {
  transition: all .2s;
}

#icon-linkedin:hover {
  fill: #0072b1;
}

#icon-whatsapp {
  margin-left: .5rem;
  transition: all .2s;
}

#icon-whatsapp:hover {
  fill: #25d366;
}

@media screen and (max-width: 980px) {
  .footer__container {
    grid-template-rows: repeat(5, auto);
    grid-template-columns: 1fr;
    padding: 5rem 0 3.2rem;
    display: grid;
  }

  .footer__logo {
    width: rem;
    height: 7rem;
    grid-area: 1 / 1 / 2 / 2;
    margin-bottom: 3rem;
  }

  .footer__nav {
    flex-direction: column;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: center;
    display: flex;
  }

  .contact__container {
    flex-direction: column;
    grid-area: 3 / 1 / 5 / 2;
    justify-self: center;
    gap: 2rem;
    margin-left: 0;
    display: flex;
  }

  .address {
    font-size: 2rem;
  }

  .email {
    align-self: center;
  }

  .company__address .col__content {
    text-align: center;
  }

  .contact {
    justify-items: center;
    margin-top: 2rem;
  }

  .footer__copyright {
    text-align: center;
    grid-area: 5 / 1 / 6 / 2;
    justify-self: center;
    margin: 0;
  }

  .footer__link {
    font-size: 2.5rem;
  }

  .vertical-line {
    display: none;
  }

  .footer .col__header {
    text-transform: uppercase;
    color: #ffffff80;
    text-align: center;
    margin-bottom: .5rem;
    font-family: suisse_intlregular;
    font-size: 1.5rem;
    display: block;
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: all .3s;
}

.loading {
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: var(--color-primary-darker);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.loading__logo {
  width: 5rem;
  animation-name: bounce;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

.go-to-top {
  width: 5rem;
  height: 5rem;
  z-index: 100;
  cursor: pointer;
  background-color: #98ddaf;
  border-radius: 50%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 2rem 2rem 0;
  transition: all 1s, transform .3s;
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
}

.go-to-top:hover {
  transform: translateY(-5px);
}

.fa-go-to-top {
  color: #fff;
}

/*# sourceMappingURL=index.1e37c5a9.css.map */
