@import "./normalize.css";

@font-face {
  font-family: "suisse_intlregular";
  src: url("../fonts/suisseintl-regular-webfont.woff2") format("woff2"),
    url("../fonts/suisseintl-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

:root {
  --color-primary: #3dbd9f;
  --color-primary-darker: #2e8e73;
  --color-secondary: #00d9ff;
  --color-accent: #ff3400;
  --color-headings: #3b3b3b;
  --color-body: #918ca4;
  --color-body-darker: #5c5577;
  --color-border: #e6ebee;
  --color-nav-element: #d2d0db;
  --color-card: #84bbbd;
  --border-radius: 100px;
  --gradient-primary: linear-gradient(to top left, #39b385, #9be15d);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  color: #444;
  line-height: 1.9;
  background-color: #f3f3f3;
  overflow-x: hidden;
  position: relative;
}

.header {
  position: relative;
}

.nav {
  width: 100%;
  height: auto;
  padding: 3rem 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 10;
  transition: all 0.5s;
}

.nav__home {
  display: inline;
  width: 39px;
  height: 45px;
  grid-area: 1 / 1 / 2 / 2;
}

.nav__toggler {
  grid-area: 1 / 1 / 2 / 2;
  justify-self: end;
  margin: 2rem 2rem 0 0;
  display: none;
  cursor: pointer;
  color: var(--color-primary);
}

.nav__toggler--close {
  grid-area: 1 / 1 / 2 / 2;
  justify-self: end;
  margin: 2rem 2rem 0 0;
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 980px) {
  body {
    overflow-x: hidden;
  }

  .nav {
    width: 100%;
    height: auto;
    padding: 0;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
    position: absolute;
    top: 0;
    z-index: 10;
    background: #fff;
    z-index: 200;
  }

  .nav__home {
    margin: 2rem 0 0 2rem;
  }

  .nav__logo {
    grid-area: 1 / 1 / 2 / 2;
  }

  /* The selectors in your original CSS have the same specificity as the selectors within your media queries (the first declarations are also targeting the same property) and because the media query rule set is being overridden I'm going to assume that it appears before the original rule set.
  The second media query selector works because it's targeting a property that wasn't set in your original CSS, so specificity isn't relevant.
  To have the first media query selector take precedence, prepend an ancestor element to it: */

  .nav > .nav__links {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 0;
    padding: 0;
    transition: all 0.5s;
  }

  .nav__links > .nav__item {
    border-bottom: 1px solid black;
    padding: 1rem;
    width: 100%;
  }

  .nav .nav__item span {
    color: #757575;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.3s;
  }

  .nav .nav__item:hover span {
    color: #000;
  }

  .nav .nav__item--highlight {
    margin-left: 1.5rem;
    border: none;
  }

  .nav .nav__item--highlight .btn__highlight {
    padding: 0.5rem 1rem;
    background-color: var(--color-primary);
    color: white;
  }

  .nav .nav__item--highlight:hover .btn__highlight {
    background-color: var(--color-primary-darker);
    color: white;
  }

  .nav > .nav__toggler {
    display: block;
  }

  .nav > .nav__toggler--close {
    display: block;
  }

  .nav.sticky {
    position: fixed;
    background-color: #fff;
  }

  .nav__links .nav__link::before {
    display: none;
  }
}

.nav__back {
  width: 100%;
  height: 12rem;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.sticky {
  position: fixed;
  background-color: rgba(21, 43, 61, 0.7);
  backdrop-filter: blur(24px);
}

.nav__logo {
  height: 45px;
}

.nav__logo:hover {
  cursor: pointer;
}

.nav__links {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.2);
  padding: 0.2rem 0.2rem 0.2rem 4.2rem;
  border-radius: var(--border-radius);
  column-gap: 0.5rem;
}

.nav__link {
  text-decoration: none;
  position: relative;
  padding: 0 1.5rem;
}

.nav__link::before {
  display: inline-block;
  content: "";
  background: rgba(21, 43, 61, 0.3);
  border-radius: 20px;
  transform-origin: center;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 34px;
  z-index: -1;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.nav__link:hover::before {
  opacity: 1;
}

.nav__btn {
  text-decoration: none;
}

.nav__item {
}

.nav__item--highlight {
  padding: 1.4rem 0.2rem 1.4rem 1rem;
}

.nav__item span {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.nav__btn span {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.btn__highlight {
  border-radius: var(--border-radius);
  background-color: var(--color-primary);
  padding: 1.5rem 3.4rem;
  transition: all 0.3s;
}

.btn__highlight:hover {
  background-color: #fff;
  color: var(--color-primary);
}

.collapsible {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.collapsible__expanded {
  max-height: 100vh;
  opacity: 1;
}

/* .header {
  overflow: hidden;
} */

.header__title {
  position: relative;
}

.header__heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.header__heading--h1 {
  color: #fff;
  font-weight: 100;
  font-size: 8rem;
  width: 100%;
  margin-bottom: 0;
  white-space: nowrap;
}

.header__heading--h4 {
  color: #fff;
  font-weight: 100;
  font-size: 2rem;
  width: auto;
}

.header__img {
  display: inline-block;
  width: 100%;
  height: auto;
  animation-name: scaleDown;
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-direction: normal;
}

@keyframes scaleDown {
  0% {
    transform: scale(1.45);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 980px) {
  .header__title {
    z-index: 100;
  }

  .header__heading--h1 {
    font-size: 4.8rem;
    white-space: nowrap;
  }

  .header__img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
}

.overlay::before {
  content: "";
  width: 100%;
  height: 99.2%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(71, 71, 71, 0.5);
}

.section__header {
  text-transform: capitalize;
}

.section {
  transition: all 1s;
  scroll-margin-top: 80px;
}

.section--hidden {
  opacity: 0;
  transform: translateY(8rem);
}

#intro {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#section--1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 0.3fr 1fr;
  text-align: center;
  position: relative;
  align-items: center;
  transition: 0.3s;
}

#section--1 .section__header {
  color: var(--color-headings);
  font-family: "Zen Antique", serif;
  font-size: 4rem;
  font-weight: 500;
  letter-spacing: -3px;
  grid-area: 1 / 1 / 2 / 3;
}

.underline {
  display: inline-block;
  grid-area: 1 / 1 / 2 / 3;
  border-bottom: 5px solid #3b3b3b;
  padding-bottom: 10px;
  width: 8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 150%);
}

.section__img {
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
  margin: 6rem 4rem 0 5rem;
  justify-self: end;
  box-shadow: 0 0 40px 10px grey;
  -webkit-mask-image: url('data:image/svg+xml;utf8,<svg preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 100 100" xml:space="preserve"><style type="text/css">.blur{filter:url(%23softedge);}</style><filter id="softedge"><feGaussianBlur stdDeviation="2.5"></feGaussianBlur></filter><g class="blur"><rect x="20" y="3" width="100" height="80"/></g></svg>');
  -webkit-mask-size: cover;
}

.section__description {
  font-family: "suisse_intlregular";
  grid-area: 2 / 2 / 3 / 3;
  font-size: 2rem;
  text-align: justify;
  font-style: italic;
  padding: 5rem 12rem 5rem 0;
  justify-self: start;
  margin-left: 4rem;
}

.divider {
  width: 46rem;
  height: 27.6rem;
  display: block;
}

.divider--left {
  margin-left: 5rem;
}

.divider--right {
  align-self: end;
}

@media screen and (max-width: 980px) {
  .divider--left {
    width: 60%;
    transform: translate(-50px, -50px);
  }

  .divider--right {
    width: 60%;
    transform: translate(0, -30%);
  }

  .divider--3 {
    transform: translate(-50px, -120px);
  }

  #section--1 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    transform: translateY(-60px);
    padding: 0 1rem;
  }

  #section--1 .section__header {
    grid-area: 1 / 1 / 2 / 2;
  }

  .underline {
    grid-area: 1 / 1 / 2 / 2;
  }

  .section__description {
    margin: 0;
    padding: 5rem 5rem;
    grid-area: 3 / 1 / 4 / 2;
    text-align: center;
  }

  .section__img {
    grid-area: 2 / 1 / 3 / 2;
    align-self: start;
    justify-self: center;
    -webkit-mask-image: none;
    -webkit-mask-size: none;
    margin: 4rem 1rem 0;
    max-width: 40rem;
  }
}

#section--2 {
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: repeat(3, 1fr);
  padding: 2.5rem 5rem;
  background: rgb(243, 243, 243);
  background: -moz-linear-gradient(
    180deg,
    rgba(243, 243, 243, 1) 0%,
    rgba(134, 196, 185, 0.4154704117975315) 51%,
    rgba(243, 243, 243, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(243, 243, 243, 1) 0%,
    rgba(134, 196, 185, 0.4154704117975315) 51%,
    rgba(243, 243, 243, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(243, 243, 243, 1) 0%,
    rgba(134, 196, 185, 0.4154704117975315) 51%,
    rgba(243, 243, 243, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f3f3f3",endColorstr="#f3f3f3",GradientType=1);
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  gap: 20px;
  transition: all 0.3s;
}

#section--2 .section__header {
  font-size: 2.3rem;
  display: inline-block;
  grid-area: 1 / 1 / 2 / 4;
}

.icon__container {
  background-color: #f3f3f3;
  width: 8em;
  height: 8em;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  margin-left: 1rem;
  position: absolute;
  left: 0;
  bottom: 0;
}

.card {
  background-color: var(--color-card);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s;
  width: fit-content;
  height: fit-content;
  overflow: hidden;
}

.card:hover {
  transform: scale(1.03);
}

.card__header {
  width: 100%;
  position: relative;
}

.card__image {
  display: inline-block;
  width: 100%;
  height: 100%;
  animation-name: scaleDown;
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-direction: normal;
}

.card__heading {
  font-family: "suisse_intlregular";
  font-size: 2.4rem;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 2.5rem;
  padding: 3rem 3.2rem 1rem;
}

.card__description {
  font-size: 1.8rem;
  color: white;
  font-weight: 400;
  line-height: 1.6em;
  padding: 1rem 3.2rem 2rem;
}

.crop {
  width: 100%;
  height: 35vh;
}

.link-arrow {
  font-family: "suisse_intlregular";
  text-decoration: none;
  text-transform: uppercase;
  color: #c3934b;
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 4rem;
  max-width: 13rem;
}

.link-arrow::after {
  font-family: "FontAwesome";
  display: inline-block;
  content: "\f061";
  margin-left: 4px;
  transition: transform 0.15s;
}

.link-arrow:hover::after {
  transform: translateX(8px);
}

.card__middle {
  grid-area: 3 / 2 / 4 / 3;
}

@media screen and (max-width: 980px) {
  #section--2 {
    display: flex;
    flex-direction: column;
    transform: translateY(-100px);
    padding: 2.5rem 2rem;
  }

  #section--2 .card {
    align-self: center;
  }

  .card {
    width: 95%;
    height: fit-content;
  }

  .card .card__description {
    line-height: 3rem;
  }

  .crop {
    width: 100%;
    height: 25vh;
  }
}

#section--3 {
  margin-top: 5rem;
  position: relative;
  transition: all 0.3s;
}

#section--3::before {
  content: "";
  width: 100%;
  height: 110%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  z-index: -5;
  border-radius: 50px;
}

#section--3 .section__header {
  font-weight: 100;
  font-size: 44px;
  text-align: center;
}

.section__subheader {
  margin-bottom: 4.5rem;
}

#section--3 .section__subheader {
  font-weight: 100;
  font-size: 20px;
  text-align: center;
}

.span__highlight {
  position: relative;
  white-space: nowrap;
}

.span__highlight::after {
  display: inline-block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: var(--gradient-primary);
  transform: scale(1.07, 1.05) skewX(-15deg);
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
}

.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 80px 80px 200px 110px;
  gap: 1rem 2rem;
  padding: 0 8rem 0 8rem;
}

.form__container {
  transform: translateY(30px);
}

.input-field {
  background: #eff3fd;
  outline: 0;
  border-radius: 10px;
  border: none;
  padding: 0 2rem 0 2rem;
  font-size: 18px;
  transition: all 0.3s;
}

.input-field:focus {
  box-shadow: 0 0 2px 3px #a8e6cf;
}

.textarea {
  background: #eff3fd;
  outline: 0;
  border-radius: 10px;
  border: none;
  padding: 2rem 2rem 0 2rem;
  font-size: 18px;
  overflow-x: hidden;
  overflow-wrap: break-word;
  resize: none;
  grid-area: 3 / 1 / 4 / 3;
  transition: all 0.3s;
}

.textarea:focus {
  box-shadow: 0 0 2px 3px #a8e6cf;
}

.btn__submit {
  grid-area: 4 / 1 / 5 / 3;
  outline: 0;
  border: none;
  margin-top: 3rem;
  cursor: pointer;
}

.form .btn__submit:hover {
  background-color: var(--color-primary-darker);
}

.btn__label {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s;
}

.icon-arrow {
  /* transform: translateX(40rem); */
  transition: all 0.3s;
}

@media screen and (max-width: 980px) {
  #section--3 {
    transform: translateY(-150px);
  }

  .form {
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
  }

  .input-field {
    width: 100%;
    height: 8rem;
  }

  .textarea {
    height: 20rem;
    padding: 3.2rem 2rem;
  }

  .btn__submit {
    height: 8rem;
  }
}

.footer {
  position: relative;
  top: 2rem;
}

.footer__container {
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: repeat(2, 1fr);
  padding: 12rem 20rem 5rem;
  row-gap: 2rem;
}

.footer::before {
  content: "";
  background-color: #245c3f;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -10;
}

.footer__logo {
  height: 50px;
  grid-area: 2 / 1 / 3 / 3;
  justify-self: center;
  margin: 3rem 0;
}

.footer__nav {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-area: 1 / 1 / 2 / 3;
  justify-content: space-evenly;
}

.footer__link {
  font-size: 1.6rem;
  text-decoration: none;
  color: #d1d1d1;
  transition: all 0.3s;
}

.footer__link:hover {
  color: #fff;
}

.footer__copyright {
  font-size: 1.4rem;
  color: #bfbfbf;
  grid-area: 3 / 1 / 4 / 2;
  margin-right:6rem;
  justify-self: end;
  align-self: center;
}

.email {
  display: flex;
  flex-direction: column;
  align-self: self-start;
}

.vertical-line {
  grid-area: 3 / 1 / 4 / 3;
  height: 100%;
  width: 1px;
  background-color: #fff;
  justify-self: center;
}

.contact__container {
  grid-area: 3 / 2 / 4 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-left: 6rem;
  width: 100%;
}

.col__header {
  color: hsla(0, 0%, 100%, 0.5);
  font-size: 1.5rem;
}

.footer__nav > .col__header {
  display: none;
}

.address {
  font-size: 1.5rem;
  color: #d1d1d1;
  font-style: normal;
  white-space: nowrap;
}

.company__address {
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  color: #d1d1d1;
  font-style: normal;
  align-self: center;
}

.contact {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
}

.footer__icon {
  width: 32px;
  height: 32px;
  display: inline-block;
}

.icon-container {
  grid-area: 2 / 1 / 3 / 3;
  transform: translateY(5px);
  cursor: pointer;
}

#icon-linkedin {
  transition: all 0.2s;
}

#icon-linkedin:hover {
  fill: #0072b1;
}

#icon-whatsapp {
  margin-left: 0.5rem;
  transition: all 0.2s;
}

#icon-whatsapp:hover {
  fill: #25d366;
}

@media screen and (max-width: 980px) {
  .footer__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, auto);
    padding: 5rem 0 3.2rem;
  }

  .footer__logo {
    grid-area: 1 / 1 / 2 / 2;
    width: rem;
    height: 7rem;
    margin-bottom: 3rem;
  }

  .footer__nav {
    display: flex;
    flex-direction: column;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: center;
  }

  .contact__container {
    grid-area: 3 / 1 / 5 / 2;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    justify-self: center;
    gap: 2rem;
  }

  .address {
    font-size: 2rem;
  }

  .email {
    align-self: center;
  }

  .company__address .col__content {
    text-align: center;
  }

  .contact {
    margin-top: 2rem;
    justify-items: center;
  }

  .footer__copyright {
    grid-area: 5 / 1 / 6 / 2;
    margin: 0;
    text-align: center;
    justify-self: center;
  }

  .footer__link {
    font-size: 2.5rem;
  }

  .vertical-line {
    display: none;
  }

  .footer .col__header {
    display: block;
    font-size: 1.5rem;
    font-family: "suisse_intlregular";
    text-transform: uppercase;
    color: hsla(0, 0%, 100%, 0.5);
    margin-bottom: 0.5rem;
    text-align: center;
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.loading {
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: var(--color-primary-darker);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading__logo {
  width: 5rem;
  animation-name: bounce;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: linear;
}

.go-to-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 0 2rem 2rem 0;
  background-color: #98ddaf;
  z-index: 100;
  cursor: pointer;
  transition: all 1s, transform 0.3s;
}

.go-to-top:hover {
  transform: translateY(-5px);
}

.fa-go-to-top {
  color: white;
}
